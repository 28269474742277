import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { FIREBASE_COLLECTIONS_NAMES } from 'src/app/shared/contants/config';

const getObservable = (collection: AngularFirestoreCollection<any>) => {
  const subject = new BehaviorSubject<any>([]);
  collection.valueChanges({ idField: 'id' }).subscribe((val) => {
    subject.next(val);
  });
  return subject;
};
@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  public userTransactionList = new BehaviorSubject<any>([]);
  // public userDetails = new BehaviorSubject<any>([]);
  userDetails: any;
  constructor(private store: AngularFirestore) {}

  getUsersTransactionById(id) {
    let payNl = getObservable(
      this.store.collection(`PayNl/${id}/PaymentRequests`, (ref) =>
        ref.orderBy('createdTime', 'desc')
      )
    ) as Observable<any>;

    payNl.subscribe((res) => {
      this.userTransactionList.next([...res]);
    });
  }

  get userTransactionListData() {
    return this.userTransactionList;
  }

  getTransactionRequest(
    isTestEnv: boolean,
    userId: string,
    transactionId: string
  ) {
    let collectionName;

    if (isTestEnv) {
      collectionName = 'PayNlvTest';
    } else {
      collectionName = 'PayNl';
    }
    return this.store
      .collection(collectionName)
      .doc(userId)
      .collection('ServicePaymentRequests')
      .doc(transactionId)
      .get();
  }

  updatePurchasedItem(docId, data){
    return this.store.collection(FIREBASE_COLLECTIONS_NAMES.purchasedServices).doc(docId).update(data)
  }

  getUsersDetailsById(id) {
    this.userDetails = this.store.doc(id).valueChanges();
    // .subscribe((res)=>{
    //   this.userDetails.next([res]);
    // })
  }
  selectedUserDetails() {
    return this.userDetails;
  }
}
